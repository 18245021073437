<template>
  <div class="my-page">


    <div class="member">
      <img src="../../assets/images/my/user.webp" class="head-photo">
      <div class="person-info" v-if="type != '属地业务'">
        <div class="username">
          <div class="id">{{ this.dealerInfo.dealerId }}</div>
          <div class="dept">{{ this.dealerInfo.officeName }}</div>
          <div class="user">{{ this.dealerInfo.businessMaster }}</div>
        </div>
        <div class="name">{{name}}</div>
      </div>
      <div class="person-info" v-if="type == '属地业务'">
        <div class="username">
          <div class="id">{{ infoList[0].value }}</div>
        </div>
        <div class="name">所属经销商：{{ infoList[2].value }}</div>
      </div>
     
    </div>
    
    
    <div class="my-info-container">
      <div class="info-card">
        
        <div class="main-info-container" v-if="type == '属地业务'">
          <div class="info-item">
            <div class="label"></div>
            <div class="value" style="font-weight: bold;font-size: 16px;">{{name}}</div>
          </div>
          <div class="info-item">
            <div class="label"></div>
            <div class="value">电话：{{ infoList[1].value }}</div>
          </div>
        </div>
        <div class="main-info-container" v-if="type != '属地业务'">
          <div class="line-flex">
            <div class="label-value">
              <div class="label">今年来款:</div>
              <div class="value">{{ this.dealerInfo.money }}万</div>
            </div>
            <div class="label-value label-value-right">
              <Icon name="down" color="red" v-if="this.dealerInfo.moneyRate && this.dealerInfo.moneyRate >= 0" style="margin-bottom:5px;transform: rotate(180deg);"/>
              <Icon name="down" color="green" v-else style="margin-top:5px;"/>
              <div class="value-right">{{ this.dealerInfo.moneyRate }}%</div>
              <div class="label-right">同比</div>
            </div>
          </div>
          <div class="line-flex">
            <div class="label-value">
              <div class="label">今年销量:</div>
              <div class="value">{{ this.dealerInfo.salesVolume }}吨</div>
            </div>
            <div class="label-value label-value-right">
              <Icon name="down" color="red" v-if="this.dealerInfo.salesRate && this.dealerInfo.salesRate >= 0" style="margin-bottom:5px;transform: rotate(180deg);"/>
              <Icon name="down" color="green" v-else style="margin-top:5px;"/>
              <div class="value-right">{{ this.dealerInfo.salesRate }}%</div>
              <div class="label-right">同比</div>

            </div>
          </div>
          <div class="line-flex">
            <div class="label-value">
              <div class="label">核心产品销量:</div>
              <div class="value">{{ this.dealerInfo.productSales }}吨</div>
            </div>
            <div class="label-value label-value-right">
              <Icon name="down" color="red" v-if="this.dealerInfo.productRate && this.dealerInfo.productRate >= 0" style="margin-bottom:5px;transform: rotate(180deg);"/>
              <Icon name="down" color="green" v-else style="margin-top:5px;"/>
              <div class="value-right">{{ this.dealerInfo.productRate }}%</div>
              <div class="label-right">同比</div>
            </div>
          </div>
<!--          <div class="info-item">
            <div class="label">核心产品销量:</div>
            <div class="value">{{this.dealerInfo.productSales}}吨</div>
          </div>
          <div class="info-item">
            <div class="label">核心产品同比:</div>
            <div class="value">{{this.dealerInfo.productRate}}%</div>
          </div>-->
        </div>
      </div>
    </div>

    <div class="task">
      <div class="info">
        <div class="dashboard">
          <router-link to="/task/listStatus">
            <div class="item">
              <div class="num">{{ this.totalData.TOTALTASK || 0 }}</div>
              <div class="label">已发布任务</div>
            </div>
          </router-link>
          <div class="divider"/>
          <router-link to="/task/listStatus?type=1">
            <div class="item">
              <div class="num">{{ this.totalData.UNDERWAY || 0 }}</div>
              <div class="label">执行中任务</div>
            </div>
          </router-link>
          <div class="divider"/>
        </div>
      </div>
      <div class="button-container">
        <router-link to="/task/publishTask">
          <Button class="button"  size="mini">下达任务</Button>
        </router-link>
      </div>
    </div>
    <div class="options-container">

      <div class="nav-header" style="padding-top: 20px;">
        <span class="title">数据查询</span>
      </div>
      <div class="nav-hr" v-if="false"></div>
      <div class="nav-row" v-if="type != '属地业务'">
        <router-link class="nav-item" to="/my/price/index">
          <img class="icon" src="../../assets/images/my/price.png" />
          <div class="label">价格查询</div>
        </router-link>

        <div class="nav-item" @click="onClickSales">
          <img class="icon" src="../../assets/images/my/sales.png" />
          <div class="label">销量统计</div>
        </div>

        <router-link class="nav-item" to="/info/contactsInfo">
          <img class="icon" src="../../assets/images/my/dealings.png" />
          <div class="label">往来明细</div>
        </router-link>
        
        <router-link class="nav-item" to="/my/back/index">
          <img class="icon" src="../../assets/images/my/back.png" />
          <div class="label">返利明细</div>
        </router-link>
      </div>

      <div class="nav-row" v-if="type != '属地业务'">

<!--        <router-link class="nav-item" to="/home/account/detail">-->
        <router-link class="nav-item" to="/info/accountBalance">
          <img class="icon" src="../../assets/images/my/account.png" />
          <div class="label">余额查询</div>
        </router-link>
        <router-link class="nav-item" to="/home/invoice">
          <img class="icon" src="../../assets/images/my/billing.png" />
          <div class="label">开票信息</div>
        </router-link>

        <router-link class="nav-item" to="/home/bank/detail">
          <img class="icon" src="../../assets/images/my/bank_number.png" />
          <div class="label">银行账户</div>
        </router-link>

        <router-link class="nav-item" to="/shidanliBusiness/knowledge">
          <img class="icon" src="../../assets/images/my/knowledge.png" />
          <div class="label">产品知识</div>
        </router-link>
      </div>
      <div class="nav-row" v-if="type == '属地业务'">
        <router-link class="nav-item" to="/shidanliBusiness/knowledge">
          <img class="icon" src="../../assets/images/my/knowledge.png" />
          <div class="label">产品知识</div>
        </router-link>
      </div>



      <!-- <a  class="option-item" @click="callPhone(dealerInfo.businessMasterPhone)">
        <div data-v-7a3d5949="" class="label">
          <div data-v-7a3d5949="">
            <img data-v-7a3d5949="" src="../../assets/images/my/phone.png" class="label-icon">
          </div>
          <div data-v-7a3d5949="">
            <span data-v-7a3d5949="">联系业务员</span>
          </div>
        </div>
        <img data-v-7a3d5949="" src="/img/right-arrow.25e702ae.png" class="right-arrow-icon">
      </a>
      <router-link
        v-for="(item, index) in showOptions"
        :key="index"
        :to="item.path"
        class="option-item"
      >
        <div class="label" >
          <div><img :src="item.icon" class="label-icon" /></div>
          <div><span>{{item.label}}</span></div>
        </div>
        <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
      </router-link>-->
    </div>


    <div class="options-container">

      <div class="nav-header" style="padding-top: 20px;">
        <span class="title">其他功能</span>
      </div>
      <div class="nav-hr" v-if="false"></div>
      <div class="nav-row" v-if="type != '属地业务'">
        <div class="nav-item" @click="callPhone(dealerInfo.businessMasterPhone)">
          <img class="icon_mini" src="../../assets/images/my/tel.png" />
          <div class="label" >联系业务员</div>
        </div>
        <router-link class="nav-item" to="/shidanliBusiness">
          <img class="icon_mini" src="../../assets/images/my/team_new.png" />
          <div class="label">我的团队</div>
        </router-link>

        <router-link class="nav-item" to="/shidanliBusiness/address">
          <img class="icon_mini" src="../../assets/images/my/address.png" />
          <div class="label">收货地址</div>
        </router-link>

        <div class="nav-item" @click="clear">
          <img class="icon_mini" src="../../assets/images/my/clean.png" />
          <div class="label">清除缓存</div>
        </div>
      </div>
      <div class="nav-row">
        <div class="nav-item" @click="clear" v-if="type == '属地业务'">
          <img class="icon_mini" src="../../assets/images/my/clean.png" />
          <div class="label">清除缓存</div>
        </div>
        <router-link class="nav-item" to="/setting/changePassword">
          <img class="icon_mini" src="../../assets/images/my/pwd.png" />
          <div class="label">修改密码</div>
        </router-link>
        <router-link class="nav-item" to="/setting/feedback">
          <img class="icon_mini" src="../../assets/images/my/msg.png" />
          <div class="label">信息反馈</div>
        </router-link>
        <router-link class="nav-item" to="/bank/child">
          <img class="icon_mini" src="../../assets/images/my/bank_child.png" />
          <div class="label" >银行卡维护</div>
        </router-link>
        <div class="nav-item" v-if="type != '属地业务'">
          <div class="icon_mini"  />
          <div class="label"></div>
        </div>
       
      </div>

    </div>

    <div class="options-container" style="padding-top: 10px;padding-bottom: 5px;" v-if="false">
      <div class="option-item" @click="clear">
        <div class="label" >
          <div><img src="../../assets/images/my/team.png" class="label-icon" /></div>
          <div><span>清除缓存</span></div>
        </div>
        <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
      </div>
      <router-link
          v-for="(item, index) in showOptions"
          :key="index"
          :to="item.path"
          class="option-item"
      >
        <div class="label" >
          <div><img :src="item.icon" class="label-icon" /></div>
          <div><span>{{item.label}}</span></div>
        </div>
        <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
      </router-link>

    </div>
    
    
    <Button size="full" @click="logout">退出登录</Button>
    <Tabbar />
  </div>

</template>

<script>
import Tabbar from '@/components/common/tabbar/index'
import Button from '@/components/common/button/CustomButton'
import * as user from '@/utils/user'
import {fetchDealerInfo,fetchDealerLogout,fetchTotalData} from '@/api/common'
import { Icon } from 'vant';

export default {
  components: {
    Icon,
    Tabbar,
    Button
  },
  data() {
    return {
      options: [
        /*{
          label: '余额查询',
          // path: '/home/account/detail',
          path: '/info/accountBalance',
          icon: require('../../assets/images/home/record.png'),
          isshow: user.getUserType() !== '属地业务'
        },
        {
          label: '银行账户',
          path: '/home/bank/detail',
          icon: require('../../assets/images/my/bank.png'),
          isshow: user.getUserType() !== '属地业务'
        },
        {
          label: '开票信息',
          path: '/home/invoice',
          icon: require('../../assets/images/my/invoice.png'),
          isshow: user.getUserType() !== '属地业务'
        },
        {
          label: '产品知识',
          path: '/shidanliBusiness/knowledge',
          icon: require('../../assets/images/my/book.png'),
          isshow: true
        },
        {
          label: '我的团队',
          path: '/shidanliBusiness',
          icon: require('../../assets/images/my/team.png'),
          isshow: user.getUserType() !== '属地业务'
        },
        {
          label: '收货地址',
          path: '/shidanliBusiness/address', 
          icon: require('../../assets/images/my/address.png'),
          isshow: user.getUserType() !== '属地业务'
        },*/
        {
          label: '修改密码',
          path: '/setting/changePassword',
          icon: require('../../assets/images/my/pwd.png'),
          isshow: true
        },
        {
          label: '用户反馈',
          path: '/setting/feedback',
          icon: require('../../assets/images/my/msg.png'),
          isshow:true
        },
      ],
      infoList: [],
      dealerInfo: {},
      totalData: {},
      name: '乔克',
      type: user.getUserType(),
    }
  },
    computed: {
        showOptions: function() {
            return this.options.filter(function (item) {
                return item.isshow
            })
        }
    },

  mounted() {
    this.infoList = [
      {
        label: '账号',
        value: user.getUserName()
      },
      {
        label: '电话',
        value: user.getPhoneNumber()
      },
      {
        label: '所属经销商',
        value: user.getDealerName()
      }
    ]
    this.role = user.getRoles()
    this.name = user.getNickName()
      // alert(user.getUserType());
    if (this.type != '属地业务') {
      this.fetchDealerInfo();
      this.getTotalData();
    }
  },

  methods: {
    logout() {
      fetchDealerLogout();
      user.logout()
      window.location.replace('/login')
    },

    clear() {
      setTimeout(() => {
        this.$toast('清除成功！')
      }, 800)
    },

    onClickSales() {
      // var baseUrl = 'https://ebs.shidanli.cn/h5report';
      var baseUrl = 'http://ebstest.shidanli.cn/crm/h5report';
      window.location.href=baseUrl +"/sales?dealerId=" + user.getDealerId();

    },

    async fetchDealerInfo() {
      try {
        let response = await fetchDealerInfo();
        if (response.status == 0) {
          this.dealerInfo = response.data;
        } else {
          this.$toast(response.message);
        }
      } catch (err) {
        this.$toast(err.message);
      }
      
    },

    // 任务中心
    async getTotalData() {
      fetchTotalData({createBy:user.getUserName()}).then(res=>{
        if(res.status == 0){
          this.totalData = res.data;
        }
      })
    },

    callPhone(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }
    
  }
}
</script>

<style lang="scss" scoped>
.my-page {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  background: #FAFBFC;
  z-index: 10;
  padding: 0 12px 92px;
  overflow: hidden;

  .member {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 15px;
    padding-top: 40px;
    .head-photo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      font-size: 18px;
    }
    .person-info {
      margin-left: 15px;
      padding: 20px 0 6px 0;
      text-align: left;
      .username {
        display: flex;
        
        .id {
          color: #2c2c2c;
          height: 20px;
          line-height: 20px;
          font-weight: bold;
          font-size: 16px;
        }

        .dept {
          text-align: center;
          background: #fff;
          width: 60px;
          height: 20px;
          line-height: 20px;
          margin-left: 15px;
          font-size: 12px;
          color: #5b5b5b;
          border-radius: 10px;
          box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
        }

        .user {
          text-align: center;
          background: #ffffff;
          width: 48px;
          height: 20px;
          line-height: 20px;
          margin-left: 15px;
          font-size: 12px;
          color: #5b5b5b;
          border-radius: 10px;
          box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
        }
      }
      .name {
        font-size: 14px;
        color: #818181;
        margin-top: 5px;
      }
     
    }
    .delete-icon {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #cd001f;
      position: absolute;
      right: 0;
      top: 0;
      img {
        height: 14px;
        width: auto;
        margin-right: 2px;
      }
      span {
        flex-shrink: 0;
      }
    }
  }
  

  .task {
    //border-top: 1px solid #EAEAEA;
    //height: 100px;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      line-height: 30px;
      text-align: center;
      color: #fff;
    }
    .info {
      margin-left: 8px;
      text-align: left;
      //height: 70px;
      flex: 1;
      .money {
        padding-top: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #818181;
      }
      .rebate {
        padding-top: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #818181;
      }

      .dashboard {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 35px 0 10px;
        justify-content: space-between;
        box-sizing: border-box;
        .item {
          text-align: center;
        }
        .divider {
          width: 1px;
          height: 40px;
          background: #e1e1e1;
          margin-top: 10px
        }
        .num {
          font-size: 18px;
          font-weight: bold;
          line-height: 21px;
          color: #2C2C2C;
          margin-top: 10px;
        }
        .label {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #818181;
          margin-top: 10px;
        }
      }
    }
    .button-container {
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      padding-right: 10px;
      .button {
        width: 80px;
        height: 34px;
        padding-left: 0;
        padding-right: 0;
        margin: 1px 0px;
        background-color: #CD001F;
      }
      
    }
  }

  .my-info-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .info-card {
      margin-top: 20px;
      width: 351px;
      //height: 130px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      background: #FFFFFF;

      .name-role-container {
        width: 100%;
        height: 57px;
        box-sizing: border-box;
        padding: 20px 0 16px 20px;
        display: flex;
        align-items: center;

        .name-container {
          font-size: 18px;
          font-weight: 500;
          color: #2c2c2c;
        }

        .role-container {
          font-size: 12px;
          color: #cecece;
          margin-left: 11px;
        }
      }
      .main-info-container {
        width: 100%;
        box-sizing: border-box;
        padding-left: 22px;
        padding-top: 10px;
        .info-item {
          display: flex;
          height: 17px;
          font-size: 14px;
          line-height: 17px;;
          .label {
            color: #2c2c2c;
          }
          .value {
            color: #2c2c2c;
            margin-left: 3px;
          }
          //&:not(:last-child) {
            margin-bottom: 7px;
          //}
        }

        .line-flex {
          width: 100%;
          font-size: 14px;
          display: flex;
          flex-wrap: wrap;
          .label-value {
            flex-shrink: 0;
            margin-bottom: 7px;
            min-height: 20px;
            line-height: 25px;
            display: flex;
            .label {
              color: #818181;
              white-space: nowrap;
              font-size: 14px;
            }
            .label-right {
              padding-left: 5px;
              color: #818181;
              font-size: 10px;
            }
            .value {
              color: #2c2c2c;
              margin-left: 3px;
              text-align: left;
              font-weight: bold;
              font-size: 17px;
            }
            .value-right {
              background: #f5f4f4;
              color: #2c2c2c;
              margin-left: 3px;
              text-align: left;
              font-weight: bold;
              font-size: 17px;
              border-radius: 10px;
              padding: 0 8px;
            }
            .input-value {
              display: flex;
              ::v-deep.van-cell, .van-field {
                padding: 0;
                .van-field__body {
                  height: 100%;
                  .van-field__control {
                    text-align: center;
                    color: #818181;
                    font-size: 14px;
                  }
                }
              }
              .input {
                width: 51px;
                height: 20px;
                border: 1px solid #CECECE;
                border-radius: 5px;
                margin-right: 6px;
                text-align: center;
              }
            }
          }
          
          .label-value-right {
            margin-left: auto;
            padding-right: 20px;
          }
          
        }
      }
    }

  }
  .options-container {
    width: 100%;
    margin-top: 14px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    margin-bottom: 10px;
    padding-bottom: 10px;

    .option-item {
      width: 351px;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      border-radius: 10px;
      background: white;
      padding: 0 12px;

      .label {
        font-size: 14px;
        color: #818181;
        display: flex;
        justify-content: flex-start;
      }

      .label-icon {
        width: 17px;
        height: 17px;
        padding-right: 7px;
        padding-top: 1px;
      }

      .right-arrow-icon {
        width: 10px;
        height: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }



    .nav-row {
      padding: 2px 29px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 12px;
        .icon {
          width: 45px;
          height: 45px;
          padding-bottom: 10px;
        }
        .icon_mini {
          width: 18px;
          height: 18px;
          padding-bottom: 15px;
        }
        .label {
          width: 65px;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #818181;
        }
        .value {
          margin-top: 6px;
          width: 40px;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: #CD011F;
        }
      }
    }
    .nav-header {
      width: 331px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /*border-bottom: 1px solid #EAEAEA;*/
      padding: 5px 10px;
      margin-top: 5px;
      .title {
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: #2C2C2C;
      }
      .team-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .team-icon {
          width: 10px;
          height: 12px;
          margin-right: 2px;
        }
        .team-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #CD001F;
        }
      }
    }
    .nav-hr {
      width: 335px;
      border-bottom: 1px solid #eaeaea;
      margin: 0 auto;
    }
    
  }
}
</style>
